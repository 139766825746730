class setCountryVat {
    constructor(select) {
        this.select = select;
        this.inputTax = $('#inputTaxId');
        this.inputCompany = $('#inputCompanyName');
        this.selectState = $('#stateselect');
        this.stateinput = $('#stateinput');
        this.taxContainer = $('[data-summary-tax-container]');
        this.loader = $('#orderSummaryLoader');
        this.tax = this.taxContainer.find('[data-summary-tax]');
        this.ajaxUrl = this.taxContainer[0].attributes['data-ajax-url'].value;
        this.form = this.select.closest('form');
        this.total = $('#totalDueToday');
        this.recurringMonthly = $('#recurringMonthly');
        this.recurringQuarterly = $('#recurringQuarterly');
        this.recurringSemiAnnually = $('#recurringSemiAnnually');
        this.recurringAnnually = $('#recurringAnnually');
        this.recurringBiennially = $('#recurringBiennially');
        this.recurringTriennially = $('#recurringTriennially');

        this.bindEvents();
    }
    bindEvents() {
        this.select.on('change', this.setCountryVat.bind(this));
        this.select.on('change', this.bindStateSelect.bind(this));
        this.inputTax.on('keyup', this.delay(this, 700));
        this.inputCompany.on('keyup', this.delay(this, 700));
        this.selectState.on('change', this.setCountryVat.bind(this));
        this.stateinput.on('keyup', this.delay(this, 700));
    }
    delay(fn, ms){
        let timer = 0,
            that = this;
        return function(...args) {
            clearTimeout(timer)
            timer = setTimeout(that.setCountryVat.bind(that), ms || 0)
        }
    }

    setCountryVat(e){
        let that = this;
        this.loader[0].style.display = "block";
        $.ajax({
            type: "POST",
            url: that.ajaxUrl,
            data: new FormData(that.form[0]),
            contentType: false,
            cache: false,
            processData: false,
            success: function success(data) {
                if (data.taxrate == 0){
                    that.tax[0].classList.add('hidden')
                    if (data.promovalue == 0 && data.promotype == null){
                        that.taxContainer[0].classList.add('hidden');
                    }
                }
                else{
                    let name = that.tax.find('.item-name'),
                        value = that.tax.find('.item-value');

                    name[0].innerHTML = data.taxname +' @ '+ data.taxrate +'%';
                    value[0].innerHTML = data.taxtotal;
                    that.tax[0].classList.remove('hidden');
                    that.taxContainer[0].classList.remove('hidden');
                    that.total[0].innerHTML = data.total;
                }
                that.total[0].innerHTML = data.total;
                if (data.totalrecurringmonthly != ""){
                    let value = that.recurringMonthly.find('.item-value');
                    value[0].innerHTML = data.totalrecurringmonthly;
                }

                if (data.totalrecurringquarterly != ""){
                    let value = that.recurringQuarterly.find('.item-value');
                    value[0].innerHTML = data.totalrecurringquarterly;
                }
                if (data.totalrecurringsemiannually != ""){
                    let value = that.recurringSemiAnnually.find('.item-value');
                    value[0].innerHTML = data.totalrecurringsemiannually;
                }
                if (data.totalrecurringannually != ""){
                    let value = that.recurringAnnually.find('.item-value');
                    value[0].innerHTML = data.totalrecurringannually;
                }
                if (data.totalrecurringbiennially != ""){
                    let value = that.recurringBiennially.find('.item-value');
                    value[0].innerHTML = data.totalrecurringbiennially;
                }
                if (data.totalrecurringtriennially != ""){
                    let value = that.recurringTriennially.find('.item-value');
                    value[0].innerHTML = data.totalrecurringtriennially;
                }
                that.loader[0].style.display = "none";
            }
        });  
    }
    bindStateSelect(){
        let stateSelect = $('body').find('#stateselect');
        if (stateSelect.length){
            stateSelect.on('change', this.setCountryVat.bind(this));
        }
    }

}

function initDataSelectors() {
    $('.page-viewcart #inputCountry').each(function () {
        new setCountryVat($(this));
    });
}

const init = {
    initDataSelectors,
};

export default init;